/* Dashboard.css */
.dashboard-container {
  text-align: center;
  margin-top: 20px;
}

.dashboard-title {
  font-size: 36px;
  font-family: 'Open Sans', sans-serif; /* Choose a beautiful font here */
  margin-bottom: 20px;
  color: #3498db; /* Match the button color for consistency */
}

.user-info {
  font-size: 18px;
  margin-bottom: 10px;
}

.user-info b {
  color: #3498db; /* Match the button color for consistency */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.dashboard-button {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease-in-out;
}

.dashboard-button:hover {
  background-color: #2980b9;
}

.sign-out-button {
  background-color: #e74c3c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.sign-out-button:hover {
  background-color: #c0392b;
}
