.container {
    padding: 20px;
  }
  
  .exit-button-black {
    padding: 10px;
    color: #272727;
    text-decoration: none;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .problem-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .problem-table th,
  .problem-table td {
    border: 1px solid #ccc;
    padding: 8px;
  }
  
  .problem-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .problem-table td {
    text-align: center;
  }
  