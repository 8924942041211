/* SignIn.css */

.sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Add this line to center the text */
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
}

.input-group input {
  width: 40%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
  width: auto;

}
