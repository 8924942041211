.dark-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #1a1a1a;
  }
  
  .button-container {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .exit-button {
    padding: 15px;
    color: #ffd770;
    text-decoration: none;
    font-size: 30px;
    text-transform: uppercase;
  }
  .button-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .dark-button {
    width: 1000px;
    height: 200px;
    margin: 100px;
    padding: 30px;
    border: 2px solid #ffd700;
    border-radius: 10px;
    background-color: #1a1a1a;
    color: #ffd700;
    font-size: 100px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none;
  }
  
  .dark-button:hover {
    background-color: #ffd700;
    color: #1a1a1a;
  }
  