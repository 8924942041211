/* App.css */

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffd500;
}


.switch-button-container {
  display: flex;
  flex-direction: row; /* Update to 'row' to place buttons side by side */
  align-items: center;
  margin-top: 10px; /* Adjust margin as needed */
  justify-content: center;
}

.switch-button {
  background-color: white;
  color: black;
  text-decoration: underline;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer; /* Adjust margin as needed */
}
